import React from "../../../../hooks/reactImport"

import { PaymentElement } from "@stripe/react-stripe-js"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { useDispatch } from "react-redux"
import { postUser } from "./postUser"
import { Notify } from "../Notification/Notify"
import { useState } from "../../../../hooks/stateHooks"

const options = {
  layout: {
    type: "tabs",
    defaultCollapsed: false,
  },
}
export default function CheckoutForm({ userCart, formdata, gatewayId }) {
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  console.log(gatewayId, "f")
  const handleSubmit = async e => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${window.location.origin}/`,
        },
        redirect: "if_required",
      })
      .then(item => {
        if (item.error) {
          Notify({
            text: `${item.error.message}`,
            type: "error",
          })
          setIsLoading(false)
        } else if (item.paymentIntent) {
          Notify({
            text: `Payment successful, you will be redirected shortly`,
            type: "success",
          })

          postUser({
            item,
            userCart,
            formdata,
            dispatch,
            gatewayId,
            setIsLoading,
          })
        }
      })
      .catch(error => {
        if (
          error?.type === "card_error" ||
          error?.type === "validation_error"
        ) {
          Notify({
            text: `${error.message}`,
            type: "error",
          })
          setIsLoading(false)
        } else {
          Notify({
            text: `${error.message}`,
            type: "error",
          })
          setIsLoading(false)
        }
      })
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={options} />
      <button
        disabled={isLoading || !stripe || !elements || userCart?.length <= 0}
        id="submit"
      >
        <span id="button-text">{isLoading ? "Processing" : "Pay now"}</span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}
